<template>
    <div :class="levelClasses" class="font-bold">
        <slot></slot>
    </div>
</template>

<script setup>
const props = defineProps({
    level: {
        type: [Number, String],
        default: 3,
        validator(value) {
            return [1, 2, 3, 4, 5, 6].includes(parseInt(value));
        },
    },
});

const levelClasses = computed(() => {
    const map = {
        2: 'text-3xl md:text-5xl',
        3: 'text-2xl md:text-4xl',
        4: 'text-xl md:text-3xl',
        5: 'text-lg md:text-2xl',
    };

    return map[parseInt(props.level)] || map[3];
});
</script>
